@import "@/styles/mixins";

:import("@/components/ui/ButtonIcon/styles.module.scss") {
  button-icon: button-icon;
}

.code {
  box-sizing: border-box; background: var(--color-neutral-900); border-radius: var(--radius-small); padding: var(--gridunit-tiny);

  &__tools {
    display: flex; flex-direction: row; justify-content: space-between; align-items: flex-start;

    &__label { padding: 0 var(--gridunit-tiny); background: var(--color-neutral-700); border-radius: var(--radius-small); line-height: 2; }

    &__buttons {
      display: flex; flex-direction: row; flex-wrap: nowrap;

      .button-icon {
        width: 24px; height: 24px;

        svg { width: 16px !important; height: 16px !important; opacity: 0.7; }

        &:hover svg { opacity: 1; }
      }
    }
  }

  &__code {
    margin-top: var(--gridunit-tiny);
  }

  PRE {
    scrollbar-color: var(--color-neutral-500) var(--color-neutral-900); scrollbar-width: thin;
    max-height: 300px;
  }
}
