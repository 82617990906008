@import "@/styles/mixins";

.list-quotes {
  display: flex; flex-direction: row; flex-wrap: wrap; column-gap: var(--gridunit); margin-top: var(--gridunit);

  > LI {
    position: relative; font-size: rem(20); color: var(--color-primary); padding: 40px 40px 24px 40px;

    svg:first-child { position: absolute; left: 0; top: 0; }

    svg:last-child { position: absolute; right: 0; top: 0; transform: rotate(180deg); }

    > SPAN {
      display: block; padding: 16px 24px; border-radius: var(--radius-default);
      background-color: var(--color-primary); color: var(--color-white);
    }

    &:before {
      content: ""; position: absolute; left: 64px; bottom: 0;
      width: 0; height: 0; border-style: solid; border-width: 25px 25px 0 0; border-color: currentColor transparent transparent transparent;
    }
  }

  > LI:nth-child(5n + 1) {
    color: var(--color-alert-500);

    > SPAN { background-color: var(--color-alert-500); }
  }

  > LI:nth-child(5n + 2) {
    color: #F7AA3B;

    > SPAN { background-color: #F7AA3B; color: var(--color-black); }
  }

  > LI:nth-child(5n + 4) {
    color: var(--color-warning-400);

    > SPAN { background-color: var(--color-warning-400); color: var(--color-black); }
  }
}