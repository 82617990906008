@import "@/styles/mixins";

.list-packages {
  margin-top: var(--gridunit);

  @include not-mobile {
    display: grid; grid-template-columns: 1fr 1fr 1fr; grid-auto-rows: auto; column-gap: var(--gridunit);
  }

  @include tablet {
    display: flex; flex-direction: column; gap: var(--gridunit);

    > LI { display: grid; grid-template-columns: 1fr 1fr; }

    &__heading { align-items: center; justify-content: center; }

    &__title { flex: 0 0 auto !important; }
  }

  > LI {
    box-sizing: border-box; border: 2px solid var(--color-neutral-50); border-radius: var(--radius-large);

    display: grid; grid-template-rows: subgrid; grid-row: span 3;

    @include mobile {
      + LI { margin-top: var(--gridunit-small); }
    }
  }

  .is-visible { background-color: green; }


  &__heading {
    background-color: var(--color-neutral-50); padding: var(--gridunit-medium) var(--gridunit-small); position: relative; border-radius: calc(var(--radius-large) - 2px) calc(var(--radius-large) - 2px) 0 0;
    display: flex; flex-direction: column; text-align: center;

    @include tablet { border-radius: calc(var(--radius-large) - 2px) 0 0 calc(var(--radius-large) - 2px); }
  }

  &__title { flex: 1 0 auto; font-size: 24px; font-weight: var(--medium); line-height: 1.3; }

  &__price {
    B, STRONG { font-size: 48px; line-height: 1.3; }
  }

  &__button { margin-top: var(--gridunit-small); }

  &__badge {
    background-color: var(--color-warning-400); position: absolute; left: 50%; top: 0; transform: translate(-50%, -50%); padding: 8px 16px; text-transform: uppercase; border-radius: var(--radius-default);
    font-size: 12px; line-height: 1.33; letter-spacing: 1.92px;
  }

  &__content { background-color: var(--color-neutral-white); padding: var(--gridunit-medium); }

  &__features {
    margin: 0; font-size: 14px; line-height: 20px;

    > LI {
      display: flex; align-items: center; gap: var(--gridunit-tiny);

      + LI { margin-top: var(--gridunit-tiny); }

      path { fill: var(--color-link); }

      SPAN { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
    }

    @include mobile {
      &.is-hidden { display: none; }
    }

    @include not-mobile {
      > LI:nth-child(10) ~ LI { display: none; }

      &.is-expanded > LI { display: flex !important; }
    }
  }

  &__tools { display: flex; margin-top: 1em; align-items: center; justify-content: center; }
}