@import "@/styles/mixins";

.details {
  + .details { margin-top: 0 !important; border-top: 1px solid var(--color-neutral-200); }

  > SUMMARY {
    cursor: pointer; list-style: none; user-select: none;
    position: relative; padding: var(--gridunit-medium) var(--gridunit-small) var(--gridunit-medium) 56px; color: inherit;
    display: flex; flex-direction: row; align-items: flex-start;

    &::marker,
    &::-webkit-details-marker { display: none; }

    svg { position: absolute; left: var(--gridunit-small); top: var(--gridunit-medium); flex: 0 0 auto; }

    @include mobile {
      font-size: 24px; line-height: 32px;
      flex-direction: column; gap: var(--gridunit-tiny);
    }
  }

  &__text { flex: 1 1 auto; }

  &__prefix { font-size: rem(20); }

  &__title {
    font-size: rem(24); font-weight: var(--medium); color: var(--color-link);

    &:first-child { font-size: rem(32px); }
  }

  &__buttons { display: flex; flex-wrap: nowrap; gap: var(--gridunit-tiny); }

  &__content {
    overflow: hidden; padding: 0 var(--gridunit-medium) var(--gridunit-medium) 56px;

    > *:first-child { margin-top: 0; }
  }

  &[open] {
    --site-bg: var(--color-neutral-50);
    background-color: var(--color-neutral-50); box-shadow: inset 2px 0 0 0 var(--color-link);
  }

  // Layouts

  &.layout-default {
    @include default-margin;

    @media print {
      > SUMMARY {
        padding: var(--gridunit-tiny) 0;

        svg { display: none; }
      }

      .details__text {
        display: flex; flex-direction: row; flex-wrap: nowrap; gap: 1em;

        * { font-size: inherit; color: inherit; }
      }
    }
  }

  &.layout-compact {
    margin-top: 0 !important;

    > SUMMARY {
      display: inline-flex; flex-direction: row-reverse; justify-content: flex-end; align-items: center; padding: var(--gridunit-medium) var(--gridunit-medium) 0 var(--gridunit-medium); gap: var(--gridunit-tiny); color: var(--color-link);

      @include mobile { justify-content: center; }

      svg { position: static; }

      path { fill: currentColor; }
    }

    @media print { display: none; }
  }

  &[open] > SUMMARY { padding-bottom: var(--gridunit-medium); }

  &.layout-compact & {
    &__title { font-size: rem(16); font-weight: var(--medium); }

    &__text { flex: 0 0 auto; }

    &__content {
      padding: 0 var(--gridunit-medium) var(--gridunit-medium) var(--gridunit-medium);
    }
  }
}
