@import "@/styles/mixins";

:import("@/components/ui/Animations/styles.module.scss") {
  animations: animations;
}

.guide {
  &__title {
    @include not-mobile {
      display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-end; justify-content: space-between;

      .animations {
        height: 2px; position: relative; overflow: visible;

        svg { position: absolute; left: 0; right: 0; bottom: -20px; top: auto; }
      }
    }

    H2 { text-transform: uppercase; letter-spacing: 0.04em; color: var(--color-highlight); font-size: inherit; line-height: inherit; font-weight: 400; margin: 0; }
  }

  &__steps { margin: var(--gridunit) 0 0 0; gap: var(--gridunit); }

  &__step {
    color: var(--color-primary-500);

    &__number { font-size: 60px; font-weight: var(--medium); line-height: 1.3; }

    &__title { font-size: 20px; font-weight: var(--medium); line-height: 1.3; text-transform: uppercase; text-wrap: balance; }

    &__description { margin-top: var(--gridunit-tiny); }
  }
}
