@import "@/styles/mixins";

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
  button__element: button__element;
  button__text: button__text;
}

.profile-header {
  margin: 0; border-bottom: 2px solid var(--color-neutral-800); background-color: var(--site-bg); position: relative; z-index: 8;
  display: flex; flex-direction: row; flex-wrap: wrap; gap: var(--gridunit-small); padding-bottom: var(--gridunit-medium);


  H1 { margin: 0; flex: 1 1 auto !important; overflow: hidden; text-overflow: ellipsis; font-size: 48px; line-height: 1.2; }

  &__buttons { flex: 0 1 auto; display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny); }

  &__sticky {
    position: sticky; top: 72px; left: 0; right: 0; z-index: 7; margin: 0 0 0 calc(var(--gridunit) * -1); width: 100%; opacity: 0; pointer-events: none;
    transition: all var(--ui-speed) var(--ui-easing) 0ms;

    &.sticking { pointer-events: auto; opacity: 1; }

    &__inner {
      position: absolute; left: 0; right: 0; top: 0;
      border-bottom: 1px solid var(--color-neutral-800); background-color: var(--site-bg); padding: 10px 0 10px var(--gridunit);
      display: flex; flex-direction: row; flex-wrap: nowrap; overflow: hidden; width: 100%; gap: var(--gridunit-small);
    }

    &__title { margin: 0; font-size: 24px; line-height: 32px; white-space: nowrap; flex: 1 1 auto !important; overflow: hidden; text-overflow: ellipsis; }

    &__buttons {
      flex: 0 1 auto; display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny);

      * { --button-size: 32px !important; }
    }
  }

  @include not-desktop {
    margin-top: var(--gridunit-small);

    H1 { font-size: 32px; }

    &__buttons {
      flex-wrap: wrap;

      > * { flex: 1 0 auto; }

      .button { display: block; }

      .button__element { width: 100%; }
    }

    &__sticky {
      display: none !important;
    }
  }

  @media print {
    &__buttons { display: none !important; }

    &__sticky { display: none !important; }
  }
}
