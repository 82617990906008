@import "@/styles/mixins";

.control-textarea {
	$element-height: 40px;

	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: flex-start; line-height: 22px; cursor: text; border-radius: 2px; position: relative; z-index: 0; background-color: var(--color-white);

	&:before {
		content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: inset 0 0 0 1px currentColor; border-radius: 2px; opacity: 0; pointer-events: none;
		transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
	}

	&:after {
		content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0;
		box-shadow: 0 0 0 4px var(--color-focus); border-radius: 2px; opacity: 0; pointer-events: none; transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
	}

	&.focus {
		&:before { opacity: 1; box-shadow: inset 0 0 0 1px var(--color-focus); }

		&:after { opacity: 0.2; }
	}

	&:hover:before { opacity: 1; }

	[disabled],
	[readonly],
	&.disabled,
	&.readonly {
		cursor: default;

		&:before { box-shadow: none; background-color: currentColor; opacity: 0.05; }
	}

	&.has-error {
		&:before { box-shadow: inset 0 0 0 1px var(--color-danger); }
	}

	&__holder { flex: 1 1 auto; }

	&__textarea {
		float: left; width: 100%; margin: 0; padding: (($element-height - 22) * 0.5) 0; font-size: inherit; background-color: transparent; border: none; box-shadow: none; border-radius: 0; outline: none; appearance: none; text-align: inherit; flex: 1 1 auto;
		min-height: $element-height; box-sizing: border-box; line-height: inherit; @include scrollbars(false); overflow-y: auto; resize: none;

		&::placeholder { color: rgba(0, 0, 0, 0.3); }

		&::-ms-clear { display: none; appearance: none; width: 0; height: 0; opacity: 0; }
	}

	&__holder:first-child &__textarea { padding-left: 12px; }

	&__holder:last-child &__textarea { padding-right: 12px; }

	@media print {
		border: 1px solid currentColor !important; page-break-inside: avoid;
	}

	&__icon {
		padding: 0; display: flex; align-items: center; justify-content: center; width: $element-height; height: $element-height; box-sizing: border-box; color: inherit; flex: 0 0 auto;

		path { fill: currentColor; }
	}

	&__addon {
		padding: 0; border-radius: 0 2px 2px 0;
		display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; min-height: $element-height;
		flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;

		> SPAN { padding: 0 12px; }

		.loading-content { width: 16px; height: 16px; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

		A,
		BUTTON {
			flex: 0 0 auto; background: transparent; border: none; outline: none; border-radius: 0; padding: 0; margin: 0; position: relative; min-width: $element-height; color: currentColor;
			cursor: pointer; pointer-events: auto;

			&[disabled],
			&:disabled {
				pointer-events: none;

				path, circle { fill: currentColor; }
			}

			&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; }

			svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
		}

		path, circle { fill: currentColor; stroke: currentColor; }

		SPAN { white-space: nowrap; }

		> svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
	}
}

