@import "@/styles/mixins";

.supporters {
  margin: var(--gridunit-large) calc(var(--gridunit) * -1) 0 calc(var(--gridunit) * -1); overflow: hidden; display: grid;

  @include mobile { margin: var(--gridunit) calc(var(--gridunit-small) * -1) 0 calc(var(--gridunit-small) * -1); }

  &__ticker {
    flex: 0 0 auto; user-select: none; overflow: hidden; display: flex; flex-wrap: nowrap; width: 100%; box-sizing: border-box;

    * { box-sizing: border-box; }

    &__group { display: flex; will-change: transform; padding: 0 40px; }

    &__list {
      display: flex; flex-direction: row; flex-wrap: nowrap; white-space: nowrap; align-items: center; justify-content: center;

      > LI {
        display: flex; flex-wrap: nowrap; align-items: center; justify-content: center;

        + LI { margin-left: 80px; }

        A {
          display: block; width: 125px; height: 60px; position: relative; opacity: 0.5;

          &:hover { opacity: 1; }

          IMG { object-fit: contain; object-position: 50% 50%; }
        }
      }
    }
  }
}
