@import "@/styles/mixins";

:import("@/components/ui/ControlInput/styles.module.scss") {
  control-input: control-input;
  control-input__input: control-input__input;
}

:import("@/components/ui/ControlDate/styles.module.scss") {
  control-date: control-date;
  control-date__input: control-date__input;
  control-date__icon: control-date__icon;
}

:import("@/components/ui/LayoutForm/styles.module.scss") {
  layout-form: layout-form;
}

.control-custom {
  $element-height: 40px;

  position: relative; background-color: var(--color-white); cursor: default; overflow: hidden; border-radius: var(--radius-small);

  transition: box-shadow var(--ui-speed) var(--ui-easing) 0ms;
  box-shadow: inset 0 0 0 1px var(--color-neutral-400);

  &.focus { box-shadow: inset 0 0 0 1px var(--color-focus), 0 0 0 4px rgba(#0B6CF4, 0.2) !important; }

  &:hover { box-shadow: inset 0 0 0 1px var(--color-neutral-800); }

  &.disabled,
  &.readonly {
    cursor: default;

    &:before { box-shadow: none; background-color: currentColor; opacity: 0.05; }
  }

  &.has-error {
    &:before { box-shadow: inset 0 0 0 1px var(--color-danger); }
  }

  &__holder {
    display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
  }

  &__placeholder {
    margin: 0; padding: 0 12px; background-color: transparent; border: none; box-shadow: none; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
    border-radius: var(--radius-small); outline: none; appearance: none; text-align: inherit; flex: 0 1 auto;

    &::placeholder { color: rgba(0, 0, 0, 0.3); }

    &::-ms-clear { display: none; appearance: none; width: 0; height: 0; opacity: 0; }

    &[value=""] ~ .control-select__addon { color: currentColor; }
  }

  &__icon {
    padding: 0; display: flex; align-items: center; justify-content: center; width: $element-height; box-sizing: border-box; color: inherit; flex: 0 0 auto;

    path { fill: currentColor; }
  }

  &__loader {
    display: flex; flex-direction: row; flex-wrap: nowrap;
    flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;
    padding: 0; align-items: center; justify-content: center;
  }

  &__arrow {
    margin-left: auto; padding: 0; display: flex; align-items: center; justify-content: center; width: $element-height; box-sizing: border-box; color: inherit; flex: 0 0 auto; pointer-events: none;

    path { fill: currentColor; }
  }

  &__count {
    margin: calc(($element-height - 24px) / 2) auto calc(($element-height - 24px) / 2) 0; line-height: 24px; background-color: var(--color-primary-500); color: var(--color-white); border-radius: var(--radius-small);
    padding: 0; width: 24px; text-align: center; box-sizing: border-box; flex: 0 0 auto; display: flex; align-items: center; justify-content: center;

    path { fill: currentColor; }
  }

  &__drop {
    position: absolute; left: 0; top: calc(100% + 4px); z-index: 200; overflow: hidden; border-radius: var(--radius-default);
    display: grid; grid-template-rows: 0fr; transition: grid-template-rows calc(var(--ui-speed) / 2) var(--ui-easing) 0ms;
    box-shadow: 0 4px 16px 0 #0216311F; background-color: var(--color-white);

    &__inner {
      max-height: 350px; height: 100%; overflow: hidden; box-sizing: border-box;
    }

    &__list {
      padding: 8px 12px;
    }

    &.is-open {
      grid-template-rows: 1fr;
      @include overflow(false);
      @include scrollbars(false);
    }
  }

  &__content {
    .layout-form { margin: 0; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr !important; grid-auto-rows: auto; gap: var(--gridunit-tiny); margin-top: var(--gridunit-tiny); }

    .control-input {
      background-color: var(--color-neutral-50); border-radius: var(--radius-small);

      &:before { border-radius: var(--radius-small); }
    }

    .control-date {
      background-color: var(--color-neutral-50); border-radius: var(--radius-small);

      &__icon {
        background-color: var(--color-neutral-50);

        path { fill: currentColor !important; }
      }

      &:before { opacity: 0; }

      &:after { border-radius: var(--radius-small); }
    }
  }


  // Sizes

  &.size-default & {
    &__holder { height: $element-height; line-height: $element-height; }

    &__arrow { width: $element-height; }
  }

  @media print { display: none; }

  // Mobile layout

  &-mobile {
    border-bottom: 1px solid var(--color-neutral-200); padding: var(--gridunit-tiny) 0;

    &:last-child { border-bottom: none; }
  ;

    &__placeholder { display: block; }

    &__content { display: block; }
  }
}
