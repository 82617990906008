@import "@/styles/mixins";

.control-toggle {
	$h: 28px;

	flex: 0 0 auto !important; position: relative; display: inline-block; width: 56px; height: $h; overflow: hidden; border-radius: $h * 0.5; transform: translateZ(0); backface-visibility: visible; color: var(--color-neutral-100);

	INPUT {
		position: absolute; right: 100%; bottom: 100%;

		&:checked + .control-toggle__inner {
			&:before { background-color: var(--color-primary-500); opacity: 1; }

			.control-toggle__knob { transform: translate(28px, 0); background-color: var(--color-white); }
		}

		&:disabled + .control-toggle__inner,
		&[disabled] + .control-toggle__inner {
			opacity: 0.5;
		}
	}

	&__inner {
		position: absolute; left: 0; right: 0; top: 0; bottom: 0; border-radius: $h * 0.5; overflow: hidden;

		&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; background-color: currentColor; transition: all var(--ui-speed) ease-in-out 0ms; }
	}

	&__knob {
		position: absolute; left: 2px; top: 2px; transition: all var(--ui-speed) ease-in-out 0ms;
		width: $h - 4; height: $h - 4; border-radius: 50%; color: var(--color-primary-500); background-color: var(--color-white);
	}
}
