@import "@/styles/mixins";

:import("@/components/ui/ControlRadio/styles.module.scss") {
  control-radio: control-radio;
}

:import("@/components/ui/ControlRadioWithLabel/styles.module.scss") {
  radio-with-label: radio-with-label;
}

.list-radios {
  display: flex; gap: var(--gridunit-tiny); margin-top: var(--gridunit-small);

  &.layout-default {
    flex-direction: column; flex-wrap: wrap;
  }

  &.layout-horizontal {
    flex-direction: row; flex-wrap: wrap;

    > LI {
      .control-radio {
        width: 24px; height: 24px; margin-top: 0 !important;

        svg { width: 24px; height: 24px; }
      }

      .radio-with-label {
        background-color: var(--color-white); padding: 8px 10px; box-shadow: 0 4px 16px 0 #0216311F;
        border-radius: 20px;
      }
    }
  }

  @media print { display: none !important; }
}
