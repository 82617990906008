@import "@/styles/mixins";

:import("@/components/ui/Scrollable/styles.module.scss") {
  scrollable: scrollable;
  scrollable__scroller: scrollable__scroller;
}

:import("@/components/ui/HeadingWithTools/styles.module.scss") {
  heading-with-tools: heading-with-tools;
  heading-with-tools__buttons: heading-with-tools__buttons;
}

:import("@/components/ui/ArticleCard/styles.module.scss") {
  article-card: article-card;
}

.carousel {
  --scroll-offset: 0;

  width: 100%; max-width: var(--content-maxwidth); grid-column: full !important; margin-left: auto; margin-right: auto; page-break-inside: avoid;

  &__wrapper {
    margin-top: var(--gridunit); display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit);
    @include mobile { margin-left: calc(var(--gridunit-small) * -1); margin-right: calc(var(--gridunit-small) * -1); }
  }


  &__scroller {
    scrollbar-width: none; flex: 1 1 auto; width: 100%; position: relative; overflow: hidden; overflow-x: auto; -ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: smooth; user-select: none; display: flex; flex-direction: row; flex-wrap: nowrap;
    border-radius: var(--radius-large); scroll-snap-type: x mandatory;

    &::-webkit-scrollbar { width: 0; height: 0; }

    &.dragging { scroll-snap-type: none; scroll-behavior: auto; }

    &.dragging * { pointer-events: none; }

    @include mobile { padding-left: var(--gridunit-small); padding-right: var(--gridunit-small); scroll-padding: var(--gridunit-small); }
  }

  &__list { display: flex; flex-direction: row; flex-wrap: nowrap; }

  &__arrow {
    flex: 0 0 48px; cursor: pointer; color: var(--color-link); padding: 0; margin: 0; box-shadow: none; appearance: none; outline: none; background: transparent; border: none; position: relative;

    svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

    path { fill: currentColor; }

    &:hover { opacity: 0.7; }

    @include mobile { display: none; }
  }

  &__item {
    flex: 0 0 calc(25% - (var(--gridunit) * 3 / 4)); overflow: hidden; box-sizing: border-box; scroll-snap-align: start;

    @include mobile { flex-basis: 280px; }

    @include tablet { flex-basis: calc(33.333% - (var(--gridunit) * 2 / 3)); }
  }

  &__item + &__item {
    margin-left: var(--gridunit);

    @include mobile { margin-left: var(--gridunit-small); }
  }

  &__item-opinion {
    background-color: var(--color-primary-50); flex: 0 0 calc(50% - (var(--gridunit) * 1 / 2)); border-radius: var(--radius-large); display: flex; flex-direction: row-reverse; flex-wrap: nowrap;

    &__logo {
      aspect-ratio: 1; width: 72px; overflow: hidden; position: relative; mix-blend-mode: multiply;

      IMG { object-fit: contain; object-position: 50% 50%; }
    }

    &__photo {
      flex: 0 0 250px; border-radius: var(--radius-large); overflow: hidden; position: relative;

      IMG { object-fit: cover; object-position: 50% 0; }
    }

    &__content { flex: 1 1 auto; padding: var(--gridunit-medium); display: flex; flex-direction: column; }

    &__text { flex: 1 0 auto; }

    &__author { margin-top: 1em; font-size: 20px; line-height: 28px; }

    &__position { font-size: 12px; line-height: 16px; }

    @include mobile { flex-basis: 100%; }

    @include not-desktop-wide {
      flex-direction: column;

      &__photo { border-radius: 0; flex: 0 0 auto; aspect-ratio: 16 / 9; }

      &__content { background-color: var(--color-primary-50); border-radius: var(--radius-large); margin-top: calc(var(--radius-large) * -1); position: relative; z-index: 1; }
    }
  }

  &__item-post {
    flex: 0 0 calc(33.333% - (var(--gridunit) * 2 / 3));

    @include mobile { flex-basis: 100%; }

    @include tablet { flex-basis: calc(50% - (var(--gridunit) * 1 / 2)); }
  }

  @include mobile {
    --scroll-offset: var(--gridunit-small);

    &.layout-blog { margin: 0; }

    &.layout-blog & {
      &__wrapper { margin: 0; }

      &__scroller { overflow: visible; flex-direction: column; padding: 0; margin: 0; }

      &__item {
        flex-basis: auto; margin: var(--gridunit) 0 0 0 !important;

        &:nth-child(3) ~ * { display: none; }
      }
    }

    &.layout-blog .heading-with-tools__buttons { display: none; }
  }
}