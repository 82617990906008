@import "@/styles/mixins";

:import("@/components/ui/Article/Paragraph/styles.module.scss") {
  article-paragraph: article-paragraph;
}

.article-fact {
  margin: var(--gridunit) 0 0 0; padding: 0 0 0 var(--gridunit-medium); border-left: 4px solid var(--color-neutral-800); word-break: break-word;

  > *:first-child { margin: 0; }

  &__title { font-weight: var(--medium); font-size: 24px; }
}
