@import "@/styles/mixins";

.trend {
  display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny); align-items: center;

  svg { width: 24px; height: 24px; }

  &.positive path { fill: var(--color-success); }

  &.negative path { fill: var(--color-danger); }
}
