@import "@/styles/mixins";

:import("@/components/ui/Buttons/styles.module.scss") {
  buttons: buttons;
}

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
  button__inner: button__inner;
}

.callout {
  font-size: 24px; line-height: 1.3; font-weight: var(--medium); margin-top: var(--gridunit-medium); page-break-inside: avoid;

  &__inner {
    padding: var(--gridunit-small);
    display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small); position: relative; overflow: hidden;
    background-color: var(--color-neutral-50); border-radius: var(--radius-default);
  }

  &__icon {
    flex: 0 0 auto; overflow: hidden; margin-top: 2px;

    path { fill: currentColor; }
  }

  &__text { flex: 1 1 auto; overflow: hidden; display: flex; align-items: center; }

  .buttons { margin: 0; flex-direction: row; justify-content: flex-end !important; }

  &:not(.intent-discrete) {
    .button__inner,
    .button__inner:after { box-shadow: none !important; border-radius: var(--radius-small) !important; }
  }

  // Intents

  &.intent-default & {
    &__inner { background-color: var(--color-primary-50); }

    &__icon { color: var(--color-focus); }

  }

  &.intent-danger & {
    &__inner { box-shadow: inset 0 0 0 1px var(--color-danger); color: var(--color-danger); }

    &__icon { color: var(--color-danger); }
  }

  &.intent-wargning & {
    &__icon { color: var(--color-warning); }

    &__inner { box-shadow: inset 0 0 0 1px var(--color-warning); }
  }

  &.intent-success & {
    &__icon { color: var(--color-success); }

    &__inner { box-shadow: inset 0 0 0 1px var(--color-success); }
  }

  &.intent-discrete & {
    &__inner {
      padding: var(--gridunit-small); font-size: 16px; line-height: 24px; font-weight: 400;
    }

    &__icon { width: 32px; height: 32px; margin-top: 4px; }
  }
}