@import "@/styles/mixins";

.carousel-widget {
  position: relative; width: 400px; height: 400px; page-break-inside: avoid;

  --ui-speed: 500ms;

  &__item {
    margin: 0 auto; box-sizing: border-box; overflow: hidden; width: 320px !important; height: 80px; box-shadow: 0 16px 32px 0 #0B6CF41F;
    background-color: var(--color-white); border-radius: 8px; color: var(--color-primary-900); text-align: center; font-size: 20px;
    display: flex !important; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small); align-items: center;

    &__label { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }
  }

  :global {
    .slick-arrow { position: absolute; left: 0; right: 0; z-index: 5; }

    .slick-prev { top: 0; }

    .slick-next { bottom: 0; }

    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
      position: relative; overflow: hidden; display: block; margin: 0 !important; padding: 0 !important;
      width: 400px; height: calc(80px * 5);

      &:focus { outline: none; }
    }

    .slick-track,
    .slick-list { transform: translate3d(0, 0, 0); }

    .slick-track {
      transition: top var(--ui-speed) cubic-bezier(.44,0,.38,1.37) 0ms !important;
      position: relative; left: 0 !important; top: 0; display: flex; flex-direction: column; margin-left: 0; margin-right: 0;
    }

    .slick-slide {
      display: none; opacity: 0; position: relative; z-index: 0; backface-visibility: hidden;
      transition: all var(--ui-speed) cubic-bezier(.44,0,.38,1.37) 0ms; width: 400px !important; height: 80px !important;

      > * { display: flex !important; }

      &[aria-hidden='true'] { transform: translate(0, 200%) scale(0.7); opacity: 0 !important; }

      &.slick-active { opacity: 1; }

      &:not([aria-hidden="true"]):has(+ * + * + .slick-center) { transform: translate(0, 200%) scale(0.7); opacity: 0; pointer-events: none; }

      &:not([aria-hidden="true"]):has(+ * + .slick-center) { transform: translate(0, 110%) scale(0.8); z-index: 1; }

      &:not([aria-hidden="true"]):has(+ .slick-center) { transform: translate(0, 50%) scale(0.9); z-index: 2; }

      &:not([aria-hidden="true"]).slick-center {
        opacity: 1; transform: translate(0) scale(1); z-index: 3;

        + * { transform: translate(0, -50%) scale(0.9); z-index: 2; }

        + * + * { transform: translate(0, -110%) scale(0.8); z-index: 1; }

        + * + * ~ *,
        + * + * + * { transform: translate(0, -200%) scale(0.7); opacity: 0; pointer-events: none; }
      }
    }

    .slick-initialized .slick-slide { display: block; }

    .slick-loading { visibility: hidden !important; }
  }
}

