@import "@/styles/mixins";

.control-toggle-with-label {
	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; gap: var(--gridunit-small); padding: 0; user-select: none; cursor: default;

	&.reversed { flex-direction: row-reverse; }

	&__label__wrapper { flex: 1 1 auto; display: flex; flex-direction: column; overflow: hidden; text-overflow: ellipsis; }

	&__label {
		&.has-error {
			color: var(--color-danger);

			&.error-animated { animation: shake 0.85s cubic-bezier(0.4, 1, 0.75, 0.9); }

			A { color: var(--color-danger); }
		}
	}

	+ .control-toggle-with-label { margin-top: var(--gridunit-tiny); }
}
