@import "@/styles/mixins";

.side-menu {
  position: sticky; left: 0; z-index: 9;

  :global(.active) { background-color: var(--color-primary-850) !important; color: var(--color-white) !important; }

  &__back {
    border-bottom: 1px solid var(--color-neutral-800);
    display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny); align-items: center;
    text-decoration: none; color: var(--color-link); font-weight: var(--medium); line-height: 32px; padding: 10px 0 10px 0;

    svg { flex: 0 0 auto; }

    path { fill: currentColor; }

    > DIV { flex: 1 1 auto; }

    @include mobile {
      display: none !important;
    }

    @include tablet {
      display: none !important;
    }
  }

  &__menu {
    margin-top: var(--gridunit-small);
    @include not-desktop { display: none !important; }

    LI + LI { margin-top: 4px; }

    A {
      display: block; padding: 4px 16px; text-transform: uppercase; color: currentColor; text-decoration: none; font-size: rem(16); border-radius: var(--radius-small);

      &:hover { color: var(--color-link-hover); }
    }
  }

  &__select {
    background-color: var(--color-primary-850) !important; color: var(--color-white) !important; border-radius: 24px !important;

    SELECT { text-transform: uppercase; font-size: rem(16); padding-left: 24px !important; }

    &:before,
    &:after { border-radius: 24px !important; }

    @include desktop { display: none !important; }
  }

  @include mobile {
    top: 82px;
  }

  @include tablet { top: 88px; }

  @include desktop { top: 72px; }
}