@import "@/styles/mixins";

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
  button__icon: button__icon;
  button__icon-left: button__icon-left;
  button__icon-right: button__icon-right;
  button__inner: button__inner;
}

:import("@/components/ui/ButtonIcon/styles.module.scss") {
  button-icon__icon: button-icon__icon;
  button-icon__inner: button-icon__inner;
}

:import("@/components/ui/SearchField/styles.module.scss") {
  search-field: search-field;
}

.header {
  position: sticky; left: 0; right: 0; top: 0; z-index: 10; user-select: none; pointer-events: auto !important;

  A { text-decoration: none; }

  .button,
  .button BUTTON {text-transform: uppercase !important; }

  .button__icon-left { width: 24px !important; height: 24px !important; }

  .button__icon-right { width: 16px !important; height: 16px !important; margin-left: -5px; }

  .button-icon__icon { width: 24px !important; height: 24px !important; }

  &__main {
    background-color: var(--header-bg); color: var(--header-color); padding: var(--gridunit-small) var(--gridunit); flex: 0 0 auto; position: relative;

    @include mobile { padding: 13px; }

    &__limiter {
      max-width: var(--content-maxwidth); margin: 0 auto;
      display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-medium);

      @include mobile { justify-content: space-between; }
    }
  }

  &__logo {
    display: flex; align-items: center; justify-content: center; flex: 0 0 auto; position: relative; z-index: 0;

    IMG {
      width: auto; height: 30px;

      @include mobile { height: 26px; }
    }

    &:focus { outline: none; }
  }

  &__menu {
    flex: 0 0 auto; white-space: nowrap; height: 40px; line-height: 40px; box-sizing: border-box; overflow: hidden; font-size: 16px;

    &__scroller {
      height: 100px; gap: var(--gridunit); width: 100%; max-width: var(--content-maxwidth); margin: 0 auto;
      overflow: hidden; overflow-x: scroll; -ms-overflow-style: auto; -webkit-overflow-scrolling: touch; scroll-behavior: auto; user-select: none;
    }

    &__items {
      margin: 0 auto; display: inline-block; padding: 0;
      @include mobile { padding: 0 var(--gridunit-small); }

      > LI {
        display: inline-block;

        A {
          display: inline-flex; align-items: center; justify-content: center; position: relative; padding: 0 10px; box-sizing: border-box;

          &:before {
            content: ""; position: absolute; left: 50%; bottom: 0; height: 2px; width: 0; background-color: currentColor; transform: translate(-50%, 0); border-radius: 1px;
            transition: all var(--ui-speed) var(--ui-easing) 0ms;
          }

          &:focus,
          &:active,
          &:hover {
            color: var(--color-primary-100); outline: none;

            &:before { width: 8px; }
          }
        }

        :global {
          *[aria-current]:not([aria-current="false"]) {
            color: var(--color-primary-400);

            &:before { width: 32px; }
          }
        }
      }
    }

    @include not-desktop-wide { display: none; }
  }

  &__buttons {
    flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0;

    @include mobile { gap: var(--gridunit-small); }

    &__burger {
      @include desktop-wide { display: none !important; }
    }

    & &__languages {
      @include not-desktop {
        display: none;
      }
    }

    & &__notifications {
      @include not-desktop {
        display: none;
      }
    }

    & &__search {
      @include not-mobile {
        display: none;
      }
    }
  }

  .search-field {
    @include mobile { display: none !important; }
  }

  @media print {
    display: none !important;
  }
}
