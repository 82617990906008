@import "@/styles/mixins";

.control-files {
	$element-height: 80px;

	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; position: relative; z-index: 0; cursor: pointer; color: var(--color-link);
	border: 2px dashed var(--color-link); border-radius: var(--radius-default);

	path { fill: currentColor; }

	&.focus {
		&:before { opacity: 1; box-shadow: inset 0 0 0 1px var(--color-focus); }

		&:after { opacity: 0.2; }
	}

	&:hover:before { opacity: 1; }

	&.disabled {
		cursor: default;

		&:before { box-shadow: none; background-color: currentColor; opacity: 0.05; }
	}

	&.has-error {
		&:before { box-shadow: inset 0 0 0 1px var(--color-danger); }
	}

	&__holder { flex: 1 1 auto; }

	&__input {
		width: 100%; margin: 0; padding: 0; font-size: inherit; background-color: transparent; border: none; box-shadow: none; border-radius: 0; outline: none; appearance: none; height: $element-height; line-height: $element-height; text-align: inherit; flex: 1 1 auto; cursor: pointer; opacity: 0;
	}

	&__label {
		position: absolute; left: 0; right: 0; top: 0; bottom: 0; pointer-events: none; font-weight: var(--medium);
		display: flex; align-items: center; justify-content: center; flex-wrap: nowrap; gap: var(--gridunit-tiny);
	}

	&__loader {
		display: flex; flex-direction: row; flex-wrap: nowrap;
		flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;
		padding: 0; align-items: center; justify-content: center;
	}

	@media print { display: none; }

	::-webkit-file-upload-button { cursor: pointer; }
}
