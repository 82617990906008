@import "@/styles/mixins";

.breadcrumb {
  flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: wrap; padding: var(--gridunit-small) 0;
  width: 100%; max-width: var(--content-maxwidth); margin: 0 auto; grid-column: full !important;

  + H1 { margin: 0; }

  A {
    text-decoration: none; color: var(--color-link);

    &:hover { text-decoration: underline; }
  }

  > LI {
    + LI {
      padding-left: var(--gridunit-tiny);

      &:before { content: "/"; margin-right: var(--gridunit-tiny); }
    }
  }

  SPAN { color: var(--color-neutral-400); }

  @include mobile { display: none; }

  &__back {
    @include not-mobile { display: none; }
  }

  @media print { display: none !important; }
}