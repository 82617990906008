@import "@/styles/mixins";

:import("@/components/ui/Scrollable/styles.module.scss") {
  scrollable: scrollable;
}

:import("@/components/ui/Well/styles.module.scss") {
  well: well;
}

:import("@/components/ui/LayoutForm/styles.module.scss") {
  layout-form: layout-form;
}

.sticky-group {
  overflow: hidden; @include default-margin;
  margin-left: calc(var(--gridunit-small) * -1); padding-left: var(--gridunit-small); margin-right: calc(var(--gridunit-small) * -1); padding-right: var(--gridunit-small);

  > *:first-child { margin-top: 0 !important; }

  > H2 {
    margin: 0; text-transform: uppercase;

    + * { margin-top: var(--gridunit-medium); }

    ~ .layout-form + .well { margin-top: var(--gridunit-small) !important; }
  }

  > H3 {
    border-bottom: 1px solid var(--color-primary-500);

    + * { margin-top: var(--gridunit-small) !important; }

    ~ P + .well { margin-top: var(--gridunit-small) !important; }

    ~ .scrollable { margin-top: var(--gridunit-small) !important; }

    ~ .scrollable TABLE { margin-top: 0; }
  }

  > H4 {
    margin-top: var(--gridunit) !important;

    + * { margin-top: var(--gridunit-small) !important; }

    ~ .scrollable { margin-top: var(--gridunit-tiny) !important; }

    ~ .scrollable TABLE { margin-top: 0; }
  }
}
