@import "@/styles/mixins";

:import("@/components/ui/Legend/styles.module.scss") {
  legend: legend;
  legend__swatch: legend__swatch;
}

.list-overviews {
  display: grid; grid-template-columns: 1fr 1fr; gap: 12px; @include default-margin; grid-auto-rows: auto;

  @include mobile { grid-template-columns: 1fr; }

  > LI { display: flex; flex-direction: column; page-break-inside: avoid; }

  &__item {
    position: relative;

    &.width-wide { grid-column: -1 / 1 !important; }

    &.width-wide &__status { flex-basis: 52px; width: 52px; height: 52px; }

    &.width-wide &__icon { flex-basis: 52px; width: 52px; height: 52px; }

    &.intent-danger &__wrapper { box-shadow: inset 0 0 0 2px var(--color-danger); }

    &.intent-warning &__wrapper { box-shadow: inset 0 0 0 2px var(--color-warning); }

    &.link { cursor: pointer; }

    &__wrapper {
      flex: 1 0 auto;
      background-color: var(--color-neutral-50); border-radius: var(--radius-default); padding: var(--gridunit-small);
      display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small);
    }

    &__status {
      flex: 0 0 40px; height: 40px; position: relative;

      &:before { content: ""; position: absolute; width: 40px; height: 40px; left: 50%; top: 50%; transform: translate(-50%, -50%); background-color: var(--color-neutral-200); border-radius: 50%; }

      &.status-danger:before { background-color: var(--color-danger); }

      &.status-warning:before { background-color: var(--color-warning); }

      &.status-success:before { background-color: var(--color-success); }
    }

    &__icon { flex: 0 0 40px; }

    &__content { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }

    &__title { text-transform: uppercase; font-size: 14px; line-height: 20px; color: var(--color-neutral-600); }

    &__value {
      font-size: 24px; line-height: 32px; font-weight: 500;

      B, STRONG { font-weight: 500; color: var(--color-primary-500); }

    }

    &__meta {
      padding: 0;

      > LI { display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; margin-top: 4px; gap: var(--gridunit-small); }

      &__label { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }

      &__value { flex: 0 0 auto; color: var(--color-primary-500); white-space: nowrap; }
    }

    &__description { margin-top: 4px; }

    &__icons {
      flex: 0 0 24px; display: flex; flex-direction: column;

      .intent-danger path { fill: var(--color-danger); }

      .intent-warning path { fill: var(--color-warning); }

      .intent-success path { fill: var(--color-success); }
    }
  }

  .legend {
    margin-top: var(--gridunit-tiny); font-size: 12px; justify-content: flex-end;

    &__swatch { width: 16px; height: 16px; flex-basis: 16px; border-radius: 50%; }
  }
}