@import "@/styles/mixins";

.list-links {
  margin-top: 0;

  > LI {
    + LI { margin-top: var(--gridunit-small); }

    A {
      display: flex; flex-direction: row; flex-wrap: nowrap; text-decoration: none; align-items: center;

      &:hover { color: var(--color-link); }

      > SPAN { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; }

      svg { flex: 0 0 auto; }

      path { fill: currentColor; }
    }
  }
}