@import "@/styles/mixins";

.sales-arguments {
  margin-top: var(--gridunit); display: grid; grid-template-columns: repeat(6, 1fr); gap: var(--gridunit);

  > LI {
    grid-column: 1 / -1 !important; display: flex; flex-direction: column; overflow: hidden;

    @include desktop-wide {
      &:nth-child(1) { grid-column: 1 / 3 !important; }

      &:nth-child(2) { grid-column: 3 / 5 !important; }

      &:nth-child(3) { grid-column: 5 / 7 !important; }

      &:nth-child(4) { grid-column: 1 / 4 !important; }

      &:nth-child(5) { grid-column: 4 / 7 !important; }
    }

    &:nth-child(5) ~ LI {
      text-align: left;

      A { display: flex; flex-direction: row; }
    }
  }

  @include not-desktop-wide {
    grid-template-columns: 1fr 1fr;

    > LI { grid-column: auto !important; }

    > LI:nth-child(4) ~ LI { grid-column: 1 / -1 !important; }
  }

  @include mobile {
    grid-template-columns: 1fr !important; gap: var(--gridunit-small);

    > LI { grid-column: 1 / -1 !important; }
  }

  A {
    flex: 1 0 auto; display: flex; flex-direction: column; padding: var(--gridunit-medium); gap: var(--gridunit-medium); background-color: var(--color-neutral-50); text-decoration: none; color: inherit; border-radius: var(--radius-large);

    &:hover { background-color: var(--color-primary-50); }
  }

  &__headline { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; flex: 0 0 auto; gap: var(--gridunit-small); }

  &__image { display: flex; }

  &__title { flex: 1 1 auto; overflow: hidden; text-overflow: ellipsis; font-size: 24px; line-height: 32px; font-weight: var(--medium); }

  &__description { flex: 1 0 auto; }

  &__button {
    flex: 0 0 auto; padding: 12px; position: relative; display: flex; align-items: center; justify-content: flex-end;

    &:before { content: ""; position: absolute; z-index: 0; right: 0; top: 0; width: 48px; height: 48px; background-color: var(--color-link); border-radius: 50%; }

    svg { position: relative; z-index: 1; }

    path { fill: var(--color-white); }
  }

  @include mobile {
    A { gap: var(--gridunit-tiny); padding: var(--gridunit-small); }

    &__image {
      IMG { width: 64px; height: 64px; }
    }

    &__title { font-size: 20px; line-height: 28px; }

    &__button {
      padding: 8px;

      svg { width: 16px; height: 16px; }

      &:before { width: 32px; height: 32px; }
    }
  }
}
