@import "@/styles/mixins";

.list-job-offers {
  margin-top: var(--gridunit);

  > LI {
    + LI { margin-top: var(--gridunit-medium); }
  }

  A { display: block; text-decoration: none; color: currentColor; }

  &__title { display: flex; flex-direction: row; align-items: center; justify-content: flex-start; gap: var(--gridunit-tiny); }

  &__location { color: var(--color-primary-400); font-size: 14px; line-height: 1.33; }

  path { fill: currentColor; }
}
