@import "@/styles/mixins";

.control-select {
	$element-height: 40px;

	display: flex; flex-direction: row; flex-wrap: nowrap; align-items: stretch; cursor: text; position: relative; z-index: 0; background-color: var(--color-white);

	&:before {
		content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; box-shadow: inset 0 0 0 1px currentColor; opacity: 0; pointer-events: none;
		transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
	}

	&:after {
		content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0;
		box-shadow: 0 0 0 4px var(--color-focus); opacity: 0; pointer-events: none; transition: opacity var(--ui-speed) var(--ui-easing) 0ms;
	}

	&.focus {
		&:before { opacity: 1; box-shadow: inset 0 0 0 1px var(--color-focus); }

		&:after { opacity: 0.2; }
	}

	&:hover:before { opacity: 1; }

	&.disabled,
	&.readonly {
		cursor: default;

		&:before { box-shadow: none; background-color: currentColor; opacity: 0.05; }
	}

	&.has-error {
		&:before { box-shadow: inset 0 0 0 1px var(--color-danger); }
	}

	&.has-value {
		.control-select__addon { color: currentColor; }
	}

	&__holder { flex: 1 1 auto; }

	&__element {
		width: 100%; margin: 0; padding: 0;
		background-color: transparent; border: none; box-shadow: none; border-radius: 0; outline: none; appearance: none; text-align: inherit; flex: 1 1 auto;
		font-size: inherit; color: currentColor;

		&::placeholder { color: rgba(0, 0, 0, 0.3); }

		&::-ms-clear { display: none; appearance: none; width: 0; height: 0; opacity: 0; }

		&[value=""] ~ .control-select__addon { color: currentColor; }
	}

	&__holder:first-child &__element { padding-left: 12px; }

	&__holder:last-child &input { padding-right: 12px; }

	&__icon {
		padding: 0; display: flex; align-items: center; justify-content: center; width: $element-height; box-sizing: border-box; color: inherit; flex: 0 0 auto;

		path { fill: currentColor; }
	}

	&__addon {
		display: flex; flex-direction: row; flex-wrap: nowrap; padding: 0; align-items: center; justify-content: center;
		flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;

		> SPAN { padding: 0 var(--gridunit-small); }

		A,
		BUTTON {
			flex: 0 0 auto; background: transparent; border: none; outline: none; border-radius: 0; padding: 0; margin: 0; position: relative; min-width: $element-height; color: currentColor;
			cursor: pointer; pointer-events: auto;

			&[disabled],
			&:disabled {
				pointer-events: none;

				path, circle { fill: currentColor; }
			}

			&:before { content: ""; position: absolute; left: 0; right: 0; top: 0; bottom: 0; }

			svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
		}

		path, circle { fill: currentColor; stroke: currentColor; }

		SPAN { white-space: nowrap; }

		> svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
	}

	&__loader {
		display: flex; flex-direction: row; flex-wrap: nowrap;
		flex: 0 0 auto; pointer-events: none; user-select: none; position: relative; line-height: $element-height; min-width: $element-height;
		padding: 0; align-items: center; justify-content: center;
	}

	&__arrow {
		padding: 0; display: flex; align-items: center; justify-content: center; width: $element-height; box-sizing: border-box; color: inherit; flex: 0 0 auto; pointer-events: none;
		position: absolute; right: 0; top: 0; bottom: 0;

		path { fill: currentColor; }
	}

	// Sizes

	&.size-default {
		border-radius: 2px;

		&:before,
		&:after { border-radius: 2px; }
	}

	&.size-default & {
		&__element { height: $element-height; line-height: $element-height; padding-right: $element-height; }

		&__arrow { width: $element-height; }
	}

	&.size-small {
		border-radius: 12px;

		&:before,
		&:after { border-radius: 12px; }
	}

	&.size-small & {
		&__element { height: 24px; line-height: 24px; font-size: 14px; padding-right: 24px; }

		&__arrow { width: 16px; height: 16px; top: 4px; right: 6px; }
	}

	@media print { display: none; }
}
