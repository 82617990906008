@import "@/styles/mixins";

:import("@/components/ui/Well/styles.module.scss") {
	well: well;
    theme-dark: theme-dark;
}

.stats {
  display: flex; flex-direction: row; flex-wrap: wrap; row-gap: var(--gridunit); column-gap: var(--gridunit-largest); @include default-margin;

  > LI { flex: 0 0 auto; }

  &__label { font-size: 16px; }

  &__value { font-size: 60px; line-height: 78px; font-weight: var(--medium); }

  @at-root .well.theme-dark & { color: var(--color-warning-400); }
}
