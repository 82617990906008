@import "@/styles/mixins";

.list-contacts {
  display: flex; flex-direction: row; flex-wrap: wrap; gap: var(--gridunit); margin-top: var(--gridunit);

  > LI { border-left: 1px solid var(--color-link); padding: 0 0 0 var(--gridunit-medium); }

  &__title { font-weight: var(--medium); font-size: 24px; line-height: 1.33; }

  &__details {
    margin-top: var(--gridunit-small); font-size: 20px; line-height: 1.33;

    > P:first-child { margin-top: 0; }
  }
}