@import "@/styles/mixins";

.list-collapsible {
  width: 100%; max-width: 1016px; grid-column: full !important; display: flex; margin-top: var(--gridunit);
  flex-direction: column; margin-left: auto; margin-right: auto; box-sizing: border-box;

  > LI {
    padding: 40px 32px;

    + LI { border-top: 1px solid var(--color-neutral-200); }

    &.is-expanded { background-color: var(--color-neutral-50); box-shadow: inset 2px 0 0 0 var(--color-link); }
  }

  &__heading {
    display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; gap: var(--gridunit-small);
    font-weight: var(--medium); color: var(--color-link); font-size: 32px; line-height: 40px; overflow: hidden; text-overflow: ellipsis;
  }

  &__icon { display: flex; flex: 0 0 auto; }

  &__description {
    font-size: 20px; line-height: 28px; margin-top: var(--gridunit-tiny);

    > *:first-child { margin-top: var(--gridunit-tiny); }
  }

  &__button {
    display: flex; margin-top: var(--gridunit-tiny);
  }

  &__details {
    overflow: hidden;

    IMG { display: block; border-radius: var(--radius-large); overflow: hidden; }
  }

  @include mobile {
    > LI { padding: var(--gridunit-small); }

    &__heading { font-size: 24px; line-height: 32px; align-items: flex-start; }

    &__icon {
      IMG { width: 32px; height: 32px; }
    }
  }

  H2, H3 {
    margin-top: var(--gridunit-medium); font-size: 24px; line-height: 1.3;

    + P { margin-top: var(--gridunit-tiny); }
  }
}