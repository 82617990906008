@import "@/styles/mixins";

:import("@/components/ui/Block/styles.module.scss") {
  theme-default: theme-default;
}

:import("@/components/ui/Tabs/styles.module.scss") {
  tabs: tabs;
  tabs__scroller: tabs__scroller;
}

.page-header {
  padding-bottom: var(--gridunit-large); position: relative; grid-column: max !important;

  &.theme-default { padding-bottom: 0; }

  &__main {
    display: flex; flex-direction: column; grid-column: leftinner !important;
  }

  &__text {
    flex: 1 0 auto; grid-column: rightinner !important; margin-top: var(--gridunit-small); text-wrap: balance;

    > *:first-child { margin-top: 0; }

    UL:not([class]) {
      padding: 0;

      > LI {
        padding: 0 0 0 var(--gridunit-medium);

        &:before { left: 0; }
      }
    }
  }

  &__back {
    flex: 0 0 auto; margin-top: var(--gridunit);

    @include mobile { display: none; }
  }

  &__image {
    aspect-ratio: 592 / 444; position: relative; grid-column: rightinner !important; display: flex;

    IMG { object-fit: cover; object-position: 50% 50%; border-radius: var(--radius-large); }
  }

  &__animation {
    pointer-events: none;

    > * { position: absolute; bottom: 0; }
  }

  H1 { margin: 0; }

  & + .tabs__scroller .tabs { margin-top: var(--gridunit-small); }

  &__logos {
    display: flex; flex-direction: row; flex-wrap: wrap; gap: var(--gridunit); margin-top: var(--gridunit);

    > LI { position: relative; width: 96px; height: 96px; }

    IMG { object-fit: contain; object-position: 50% 50%; mix-blend-mode: multiply; }
  }

  @include mobile {
    &__main { grid-column: limited !important; }

    &__text { grid-column: limited !important; }

    &__image {
      grid-column: limited !important; aspect-ratio: 328 / 246; margin-top: var(--gridunit);

      &:before { content: ""; position: absolute; left: calc(var(--gridunit-small) * -1); right: calc(var(--gridunit-small) * -1); top: 50%; bottom: 0; background-color: var(--color-white); }
    }

    &__animation {
      position: static; height: auto;

      > * { top: auto; left: var(--gridunit-small); bottom: 0; }
    }

    &:has(&__animation) { padding-bottom: 180px; }

    &:has(&__image) { padding-bottom: 0; }
  }

  @include not-mobile {
    &:has(&__animation) { padding-bottom: 200px; }

    &:not(:has(&__image)) & {
      &__main { grid-column: limited !important; }

      &__back { display: none; }
    }
  }
}
