@import "@/styles/mixins";

.article-card {
  display: flex; flex-direction: column; position: relative; z-index: 0; mix-blend-mode: darken; overflow: hidden;

  A { text-decoration: none; }

  &__image {
    flex: 0 0 auto; display: block; aspect-ratio: 592 / 333; position: relative; overflow: hidden; z-index: 0; border-radius: var(--radius-large);

    IMG { transition: all 150ms var(--ui-easing) 0ms; object-fit: cover; object-position: 50% 0; z-index: 1; }

    &:hover IMG { transform: scale3d(1.02, 1.02, 1); }

  }

  &__content {
    flex: 1 0 auto; display: flex; flex-direction: column; gap: 4px; margin-top: var(--gridunit-tiny);

    &:first-child { margin-top: 0 !important; }
  }

  &__title {
    font-size: 24px; line-height: 1.333; font-weight: var(--medium);
    display: block; text-overflow: ellipsis; overflow: hidden; hyphens: auto; text-wrap: balance;

    &:hover { opacity: 0.6; }

    @include mobile {
      font-size: 20px; line-height: 28px;
    }
  }

  &__prefix { color: var(--color-primary-500); }
}