@import "@/styles/mixins";

:import("@/components/ui/FormRow/styles.module.scss") {
  form-row: form-row;
  form-row__label: form-row__label;
  form-row__content: form-row__content;
  form-row__content__items: form-row__content__items;
}

.layout-form {
  @include default-margin; row-gap: var(--gridunit-small); column-gap: var(--gridunit); align-items: flex-start;

  &.layout-default {
    display: grid; grid-template-columns: 1fr 1fr; grid-auto-rows: min-content;
  }

  &.layout-details {
    margin-top: var(--gridunit-medium);

    A { color: var(--color-link); }

    > .form-row {
      display: grid; grid-template-columns: 200px 1fr; grid-auto-rows: auto; border-bottom: 1px solid var(--color-neutral-200); gap: 1em;
      padding: var(--gridunit-tiny) 0;

      > .form-row__label {
        font-size: inherit; line-height: inherit; color: inherit;

        LABEL { font-size: inherit; line-height: inherit; color: inherit; }
      }
    }


    @include mobile {
      > .form-row {
        display: block;

        > .form-row__label { font-size: 12px; line-height: 16px; }
      }
    }
  }

  &.layout-filter {
    display: grid; grid-template-columns: 340px 1fr; grid-auto-rows: auto; margin-top: 0;

    > .form-row {
      display: flex; flex-direction: column; grid-column: auto !important; margin-top: var(--gridunit-medium);

      &:first-child > .form-row__content > .form-row__content__items { grid-template-columns: 1fr; }

      &:first-child:last-child {
        grid-column: span 2 !important;

        > .form-row__content > .form-row__content__items { grid-template-columns: repeat(4, 1fr);

          @include tablet { grid-template-columns: 1fr 1fr; }
          @include mobile { grid-template-columns: 1fr; }

          > * { grid-column: auto; }
        }
      }

      > .form-row__label { text-transform: uppercase; font-size: inherit; font-weight: 500; color: var(--color-neutral-800); }

      > .form-row__content {
        > .form-row__content__items {
          display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; grid-auto-columns: auto; gap: var(--gridunit-tiny);

          > * { grid-column: span 2 !important; }

          @include desktop-wide {
            :global(.wide) { grid-column: span 3 !important; }
          }

          @include not-desktop-wide { grid-template-columns: 1fr 1fr 1fr 1fr; }

          @include not-desktop { grid-template-columns: 1fr 1fr; }

          @include mobile { gap: 0; }
        }
      }
    }


    @include mobile {
      grid-template-columns: 1fr;

      > .form-row__content > .form-row__content__items { grid-template-columns: 1fr; }
    }
  }

  &.layout-api {
    background: var(--color-neutral-800); color: var(--color-white); border-radius: var(--radius-default); padding: var(--gridunit-tiny);
    display: flex; flex-direction: column; gap: var(--gridunit-small); font-size: rem(12); line-height: 1.2;

    > .form-row {
      width: 100%; overflow: hidden; flex: 1 0 auto; box-sizing: border-box;

      > .form-row__label { color: inherit; opacity: 1; font-size: rem(12); }
    }
  }
}
