@import "@/styles/mixins";

:import("@/components/ui/LayoutOverlay/styles.module.scss") {
  layout-overlay: layout-overlay;
}

:import("@/components/ui/LoadingContent/styles.module.scss") {
  loading-content: loading-content;
}

.chart-relations {
  margin-top: var(--gridunit-tiny); border: 2px solid var(--color-neutral-200); border-radius: var(--radius-small);
  position: relative; z-index: 0; page-break-inside: avoid; aspect-ratio: 6 / 4;

  &__tools {
    position: absolute; right: var(--gridunit-small); bottom: var(--gridunit-small); z-index: 5;
    display: flex; flex-direction: column; gap: var(--gridunit-tiny);

    BUTTON {
      width: 36px; height: 36px; padding: 0; margin: 0; position: relative; cursor: pointer; color: inherit;
      background-color: var(--color-white); border: 1px solid var(--color-neutral-200); border-radius: var(--radius-small);

      &:hover { border-color: var(--color-link-hover); color: var(--color-link-hover); }

      svg { position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }

      path { fill: currentColor; }
    }
  }

  &__locked {
    position: absolute; inset: 0; z-index: 1; opacity: 0; pointer-events: none; background: #ffffff; transition: opacity var(--ui-speed) var(--ui-easing) 0ms;

    &.active { opacity: 0.5; pointer-events: auto; }
  }

  &__add-on {
    position: absolute; left: var(--gridunit-small); right: var(--gridunit-small); top: var(--gridunit-small); z-index: 5;

    > *:first-child { margin-top: 0; }
  }

  &__pre { flex: 0 0 auto; }

  &__post { flex: 0 0 auto; }

  @at-root .layout-overlay & { flex: 1 0 500px; margin-top: var(--gridunit); height: 500px; aspect-ratio: auto; }

  :global .vis-network { width: 100% !important; height: 100% !important; }

  @media print {
    &__add-on { display: none; }

    &__tools { display: none; }
  }

  .loading-content { z-index: 6; opacity: 0.9; left: auto; bottom: auto; right: var(--gridunit); top: var(--gridunit); }
}