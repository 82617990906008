@import "@/styles/mixins";

:import("@/components/ui/Button/styles.module.scss") {
	button: button;
}

.form-row {
	display: flex; flex-direction: column; gap: 4px; text-align: left; page-break-inside: avoid;

	@include mobile {
		grid-column: span 2 !important;
	}

	@include not-mobile {
		&.size-default { grid-column: span 2 !important; }

		&.size-narrow { grid-column: auto !important; }
	}

	&__label {
		flex: 0 0 auto; display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit); font-size: 12px; line-height: 16px; color: var(--color-neutral-600);

		LABEL { flex: 1 1 auto; text-overflow: ellipsis; word-break: break-all; display: block; }

		&__required { color: var(--color-link); }
	}

	&__content {
		flex: 1 0 auto; display: flex; flex-direction: column; gap: 4px;

		&__items {
			display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny);

			> * { flex: 1 1 auto; max-width: 100%; }

			.button { flex: 0 0 auto; }
		}

		&__description { font-size: 12px; line-height: 16px; color: var(--color-neutral-600); }

		&__error { font-size: rem(12); color: var(--color-danger); }
	}

	&__value { padding: 0; }

	&.has-error &__label { color: var(--color-danger); }

	.buttons { width: 100%; overflow: hidden; }
}
