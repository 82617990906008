@import "@/styles/mixins";

:import("@/components/ui/Article/Paragraph/styles.module.scss") {
  article-paragraph: article-paragraph;
}

.article-number {
  margin: var(--gridunit) 0 0 0; word-break: break-word;
  display: flex; flex-wrap: nowrap; align-items: flex-start; gap: var(--gridunit);

  &__number {
    font-size: 96px; line-height: 1; white-space: nowrap;

    > * { margin-top: 0 !important; }
  }

  &__content {
    font-size: 32px; line-height: 1.2; align-self: center;

    > * { margin-top: 0 !important; }

  }

  @include mobile {
    gap: var(--gridunit-small);

    &__number { font-size: 64px; }

    &__content { font-size: 24px; }
  }
}
