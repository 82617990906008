@import "@/styles/mixins";

:import("./../Buttons/styles.module.scss") {
  buttons: buttons;
}

:import("./../Button/styles.module.scss") {
  button: button;
  button__inner: button__inner;
}

:import("./../LayoutSplit/styles.module.scss") {
  layout-split: layout-split;
}

.promo {
  display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit); align-items: flex-start;
  margin: var(--gridunit) 0 0 0;

  @include mobile { display: block; }

  &__text {
    flex: 1 1 0;

    > *:first-child { margin-top: 0; }
  }

  &__image {
    flex: 1 1 0;

    > *:first-child { margin-top: 0; }

    @include mobile { margin-top: var(--gridunit); }
  }

  &__heading {
    display: flex; flex-direction: row; justify-content: space-between; align-items: center; gap: var(--gridunit-medium);

    .buttons { margin: 0; }

    @include mobile {
      flex-direction: column; align-items: center;
    }
  }

  H3 {
    font-size: 20px; line-height: 1.3; font-weight: 400; margin: var(--gridunit-small) 0 0 0;

    + P { margin-top: 4px; }
  }

  HR { height: 1px; margin: 1em 0 0 0; border: none; background-color: var(--color-neutral-200); }

  UL:not([class]) {
    color: var(--color-primary-500); font-weight: 500; margin: var(--gridunit-small) 0 0 0;

    > LI {
      padding: 0 0 0 var(--gridunit-small);

      &:before { left: 0; }

      + LI { margin-top: 0; }
    }
  }

  .layout-split {
    @include not-mobile {
      display: grid !important; grid-template-columns: auto 1fr !important; gap: var(--gridunit);
    }

    > * { margin-top: 0 !important; }

    UL:not([class]) { font-weight: 400; color: inherit; }
  }
}