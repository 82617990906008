@import "@/styles/mixins";

.list-certificates {
  display: flex; flex-direction: row; flex-wrap: wrap; gap: var(--gridunit-small); justify-content: center; align-items: flex-start; align-content: flex-start;
  margin-top: var(--gridunit);

  > LI {
    display: flex; flex: 0 0 auto;

    A {
      flex: 0 0 auto; position: relative; display: block; width: 100px; height: 100px; overflow: hidden;

      IMG { object-fit: cover; object-position: 50% 50%; }
    }
  }
}