@import "@/styles/mixins";

:import("@/components/ui/Button/styles.module.scss") {
  button: button;
  button__inner: button__inner;
}

.copy-text {
  background: var(--color-white); border-radius: var(--radius-default); padding: 6px 6px 6px var(--gridunit-small); width: 100%; overflow: hidden;
  display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small); align-items: center;

  &__text { flex: 1 1 auto; white-space: wrap; word-wrap: break-word; overflow: hidden; }

  .button__inner { padding-left: 0 !important; padding-right: 0 !important; color: var(--color-link); font-weight: 400 !important; }
}
