@import "@/styles/mixins";

:import("@/components/ui/ArticleCard/styles.module.scss") {
  article-card__title: article-card__title;
}

.list-articles {
  display: grid; grid-template-columns: repeat(6, 1fr); gap: var(--gridunit); margin-top: var(--gridunit); grid-auto-rows: auto;

  > LI {
    position: relative; text-overflow: ellipsis; z-index: 0;
    display: flex; flex-direction: column; overflow: hidden; page-break-inside: avoid;
  }

  &.layout-default {
    > LI {
      grid-column: 1 / -1 !important;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) { grid-column: span 3 !important; }

      &:nth-child(4) ~ LI { grid-column: span 2 !important; }
    }

    @include mobile {
      grid-template-columns: 1fr 1fr; gap: var(--gridunit-small);

      > LI {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) { grid-column: span 2 !important; }

        &:nth-child(4) ~ LI { grid-column: span 1 !important; }
      }
    }
  }

  &.layout-compact {
    grid-template-columns: 1fr 1fr 1fr;

    @include mobile {
      grid-template-columns: 1fr 1fr; gap: var(--gridunit-small);
    }

    .article-card__title {
      font-size: rem(20) !important;
    }
  }
}