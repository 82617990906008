@import "@/styles/mixins";

.percentage {
  display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-small); align-items: center;

  &__bar {
    flex: 0 0 80px; width: 80px; height: 8px; background-color: var(--color-neutral-100); border-radius: 2px; overflow: hidden;

    > * { height: 8px; background-color: var(--color-primary-500); }
  }
}
