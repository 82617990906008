@import "@/styles/mixins";

.chart {
  position: relative; width: 100%; pointer-events: auto; aspect-ratio: 1;
  z-index: 0; margin-top: var(--gridunit-medium); page-break-inside: avoid;

  > * { position: absolute; left: 0; right: 0; top: 0; bottom: 0; }

  &-swatch {
    display: flex; flex-direction: row; flex-wrap: nowrap; gap: var(--gridunit-tiny); align-items: center;

    &__swatch {
      flex: 0 0 16px; width: 16px; height: 16px; border-radius: 2px;

      &:last-child { margin-left: auto; margin-right: auto; }
    }

    &__label { white-space: nowrap; }
  }

  &-holder {
    margin-top: var(--gridunit-medium); position: relative;
  }

  @media print {
    CANVAS { width: 100% !important; height: 100%; }
  }
}

.chart-scroller {
  overflow: hidden; overflow-x: auto; position: relative; z-index: 0; box-sizing: border-box; line-height: 0;

  &.interactive { user-select: none; }

  &.dragging * { pointer-events: none; }

  .chart { margin: 0 !important; }

  &__wrapper {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 100%;
    line-height: 1.33;
  }

  &__chart-a {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    box-sizing: border-box;

    .chart { margin: 0 !important; }
  }

  &__chart-b {
    background: var(--site-bg);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    overflow: hidden;
    box-sizing: border-box;
    pointer-events: none !important;

    .chart { margin: 0 !important; }

    * { pointer-events: none !important; }

    &__inner {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  &__chart-c {
    background: var(--site-bg);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    overflow: hidden;
    box-sizing: border-box;
    pointer-events: none !important;

    .chart { margin: 0 !important; }

    * { pointer-events: none !important; }

    &__inner {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  TABLE {
    width: auto; min-width: 100%; margin: 0; font-size: 12px; line-height: 16px;

    TD { padding-left: var(--gridunit-tiny); padding-right: var(--gridunit-tiny); border-color: var(--color-neutral-200); }

    TH:first-child,
    TD:first-child { background-color: var(--color-white); position: sticky; left: 0; z-index: 1; padding-left: 0; }
  }

  @media print {
    &__chart-a { width: 100%; }

    &__chart-b { display: none; }

    &__chart-c { display: none; }
  }
}

.chart-tooltip {
  position: absolute; width: 10px; height: 10px; pointer-events: none; z-index: 20;
  transform: translate(-50%, -50%); transition: all 100ms linear 0ms;

  &__inner {
    position: absolute; left: 50%; top: 12px; transform: translate(-50%, 0); white-space: nowrap; pointer-events: none;
    background-color: rgba(0, 0, 0, 0.7); font-size: rem(11); line-height: 1.3; color: var(--color-white); border-radius: 4px;
    padding: 12px 16px;
  }

  &__swatch { width: 10px; height: 10px; border-radius: 50%; }

  TABLE {
    margin: 0; padding: 0;

    TH, TD { padding: 0; margin: 0; border: none; font-size: inherit; line-height: 1.3; }

    TH { padding-bottom: 4px; font-weight: var(--bold); }

    TD > DIV { display: flex; flex-direction: row; flex-wrap: nowrap; align-items: center; gap: var(--gridunit-tiny); }
  }
}