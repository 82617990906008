@import "@/styles/mixins";

.slideshow {
  @include block-margin;
  @include mobile { margin-top: 0; }

  --height: calc(100vh - 72px - var(--gridunit) - var(--gridunit));

  &__content { flex: 0 0 50%; }

  &__images {
    flex: 0 0 50%; position: relative;

    &__holder {
      position: sticky; left: 0; top: calc(72px + var(--gridunit)); right: 0; bottom: 0; width: 100%; height: var(--height);

      > * { position: absolute; left: 0; top: 0; bottom: 0; right: 0; }
    }

    @include mobile { display: none; }
  }

  &__slides {
    flex: 0 0 50%;

    @include mobile { flex: 1 1 50%; }
  }

  &__slide {
    @include mobile {
      margin-top: var(--gridunit-large);
    }

    &__content {
      flex: 0 0 50%; box-sizing: border-box; padding: 100px; height: var(--height);
      display: flex; flex-direction: column; justify-content: center; line-height: 1.35;

      P { font-size: 20px; }

      H2,
      H3 {
        margin: 0; font-size: 40px;

        @include mobile { margin-top: var(--gridunit-medium); font-size: 32px; }
      }

      @include not-desktop-wide { padding: var(--gridunit) var(--gridunit) var(--gridunit) 0; }

      @include mobile { padding: 0; height: auto; min-height: 0 !important; }
    }

    &__image {
      position: relative; padding-top: 100%; border-radius: var(--radius-default); overflow: hidden;

      IMG { object-fit: cover; object-position: 50% 0; }

      @include not-mobile { display: none; }
    }

    &__fleximage { border-radius: var(--radius-large); overflow: hidden; }

    &__clipper { width: 100%; overflow: hidden; will-change: height; }

    &__holder {
      height: var(--height); width: 100%; position: relative;

      IMG { object-fit: cover; object-position: 50% 0; }
    }
  }

  &__wrapper { display: flex; flex-direction: row-reverse; flex-wrap: nowrap; position: relative; z-index: 0; }

  &__animation {
    position: sticky; left: 0; right: 0; top: 0; z-index: 1;

    > * {
      position: absolute; right: 0; top: 0; transform: translate(-200px, -80px);

    }

    @include mobile {
      position: relative;

      > * { transform: translate(-50%, 0); left: 50%; right: auto; }
    }
  }
}
